import React, { FC, useState } from 'react';
import AssistantHeader from './assistant-header/AssistantHeader';
import { Assistant, Message } from './types';
import {
  Bars3Icon as Hamburger,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import SideBar from './mobile-components/SideBar';
import { lightIndigo } from '@utils/tailwindStyles';
import ChatInterface from './chat-interface/ChatInterface';
import DialogComp from '@components/dialog/Dialog';
import System from './system/System';
import Spinner from '@components/spinner/Spinner';
import { User } from '@/types/global';
import CustomLogin from './custom-login/CustomLogin';
import BillingDialog from './billing-dialog/BillingDialog';
import BillingSuccess from './billing-dialog/BillingSuccess';

type Props = {
  user?: User;
  assistant?: Assistant;
  query: string;
  search: (query: string) => void;
  clear: () => void;
  assistants: Assistant[];
  slug: string;
  onChange: (slug: string) => void;
  messages: Message[];
  input: string;
  setInput: (input: string) => void;
  generateConversation: () => void;
  updateUserConversation: () => void;
  streamMessage: string;
  isLoading: boolean;
  system: string;
  setSystem: (val: string) => void;
  stopGeneration: () => void;
  isBillingDialogOpen: boolean;
  setIsBillingDialogOpen: (val: boolean) => void;
  isBillingSuccessOpen: boolean;
  setIsBillingSuccessOpen: (val: boolean) => void;
};

const MobileView: FC<Props> = ({
  assistant,
  query,
  search,
  clear,
  assistants,
  slug,
  onChange,
  messages,
  input,
  setInput,
  generateConversation,
  updateUserConversation,
  streamMessage,
  isLoading,
  system,
  setSystem,
  user,
  stopGeneration,
  isBillingDialogOpen,
  setIsBillingDialogOpen,
  isBillingSuccessOpen,
  setIsBillingSuccessOpen,
}) => {
  const [showAssistants, setShowAssistants] = useState(false);
  const [showSystem, setShowSystem] = useState(false);

  if (!assistant) {
    return <Spinner title='Loading AI Assistances...' />;
  }

  return (
    <div className='p-4'>
      <div className='flex items-center justify-between border-b border-slate-200 pb-2'>
        <div className='flex items-center gap-4'>
          <Hamburger onClick={() => setShowAssistants(true)} width={30} />
          <AssistantHeader assistant={assistant} isMobile />
        </div>
        <PencilSquareIcon
          color={lightIndigo}
          width={30}
          onClick={() => setShowSystem(true)}
        />
      </div>
      {user ? (
        <ChatInterface
          assistant={assistant}
          messages={messages}
          input={input}
          setInput={setInput}
          generateConversation={generateConversation}
          updateUserConversation={updateUserConversation}
          streamMessage={streamMessage}
          system={system}
          isLoading={isLoading}
          stopGeneration={stopGeneration}
          user={user}
        />
      ) : (
        <CustomLogin />
      )}

      <SideBar
        isOpen={showAssistants}
        setIsOpen={setShowAssistants}
        query={query}
        search={search}
        clear={clear}
        assistants={assistants}
        slug={slug}
        onChange={onChange}
        user={user}
      />

      <DialogComp isOpen={showSystem} setIsOpen={setShowSystem} fullWidth>
        <System assistant={assistant} system={system} setSystem={setSystem} />
      </DialogComp>

      <DialogComp
        isOpen={isBillingDialogOpen}
        setIsOpen={setIsBillingDialogOpen}
      >
        <BillingDialog
          closeModal={() => setIsBillingDialogOpen(false)}
          user={user}
        />
      </DialogComp>

      <DialogComp
        isOpen={isBillingSuccessOpen}
        setIsOpen={setIsBillingSuccessOpen}
      >
        <BillingSuccess />
      </DialogComp>
    </div>
  );
};

export default MobileView;
