import React, { FC } from 'react';
import { BillingStatus as TBillingStatus } from '../../../types/global';
import { safeCredentials, handleErrors } from '@utils/fetch';

interface Details {
  html: JSX.Element;
}

const manageBilling = () => {
  fetch(`/api/internal/billing/open_stripe_portal`, safeCredentials({}))
    .then(handleErrors)
    .then(data => {
      window.open(data.session.url, '_blank');
    });
};

const badges: Record<TBillingStatus, Details> = {
  no_plan: {
    html: (
      <a href='/pricing'>
        <button
          type='button'
          className='rounded bg-gradient-to-r from-purple-500 to-pink-500 px-2 py-1 text-center text-sm font-semibold text-white hover:bg-gradient-to-l focus:outline-none focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800'
        >
          Start Free Trial (7 Days) Now
        </button>
      </a>
    ),
  },
  trialing: {
    html: (
      <button onClick={manageBilling}>
        <span className='inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700'>
          You're on Free Trial (7 Days)
        </span>
      </button>
    ),
  },
  paying: {
    html: (
      <button onClick={manageBilling}>
        <span className='inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700'>
          You're on a Paid Plan
        </span>
      </button>
    ),
  },
  paying_past_due: {
    html: (
      <span
        className='inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700'
        onClick={manageBilling}
      >
        <strong>Your payment has failed</strong>
      </span>
    ),
  },
};

type Props = {
  status: TBillingStatus;
};

const BillingStatus: FC<Props> = ({ status }) => {
  const html = badges[status].html;

  return <>{html}</>;
};

export default BillingStatus;
