import React, { FC } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

type Props = {
  query: string;
  search: (query: string) => void;
  clear: () => void;
};

const SearchBar: FC<Props> = ({ query, search, clear }) => {
  return (
    <div className='min-w-0 lg:px-0'>
      <div className='flex items-center md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0'>
        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              id='search'
              name='search'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                search(e.target.value);
              }}
              value={query}
              className='block h-12 w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-indigo-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
              placeholder='Search for an AI Assistant'
              type='search'
            />
            {query.length > 0 && (
              <div className='absolute bottom-0 right-4 -translate-y-1/2 '>
                <button
                  onClick={clear}
                  type='button'
                  className='rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800'
                >
                  Clear
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
