import React from 'react';
import { Button } from '@components/button/Button';
import { useCustomLoginSuccess } from '@components/google-login/helpers';

const loginCb = () =>
  location.replace(`${location.pathname}?google_login=true`);

const CustomGoogleBtn = () => {
  const { login } = useCustomLoginSuccess(loginCb);

  return (
    <Button
      type='button'
      className='mt-4'
      onClick={() => login()}
      text='Start Conversation'
      size='full'
    />
  );
};

export default CustomGoogleBtn;
