import slugify from 'slugify';
import { ClassValue, clsx } from 'clsx';

export const goTo = (path: string, replace?: boolean) => {
  if (replace) return window.location.replace(path);

  return window.location.assign(path);
};

export const slugifyString = (key: string) =>
  slugify(key, {
    replacement: '_',
    lower: true,
    trim: true,
  });

export const scrollTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

export const scrollIntoView = (
  node: HTMLElement | Element,
  position?: 'start' | 'end',
  behavior?: 'smooth' | 'auto',
) => {
  node.scrollIntoView({
    behavior: behavior || 'smooth',
    block: position || 'start',
  });
};

export const truncateHTML = (content: string, max: number) =>
  content.slice(0, max) + (content.length > max && '<br>......');

export const hexToRgb = (hex: string) => {
  const rgb = hex.match(/\w\w/g)?.map(x => parseInt(x, 16));

  if (!rgb) return null;

  const [r, g, b] = rgb;

  return `rgb(${r}, ${g}, ${b})`;
};

export const cn = (...inputs: ClassValue[]) => clsx(inputs);
