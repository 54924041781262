import React, { FC } from 'react';
import { Assistant } from '../types';
import Item from './Item';
import classNames from 'classnames';
import { useViewport } from '@context/viewport';

type Props = {
  assistants: Assistant[];
  slug: string;
  onChange: (slug: string) => void;
};

const Assistants: FC<Props> = ({ assistants, slug, onChange }) => {
  const { isDesktop, isWide } = useViewport();

  // Define a custom comparison function to determine the order of the items
  const compareAssistants = (a: Assistant, b: Assistant) => {
    if (a.slug === 'gpt-4') {
      return -1; // a comes before b
    } else if (b.slug === 'gpt-4') {
      return 1; // b comes before a
    } else if (a.slug === 'chatgpt' && b.slug !== 'gpt-4') {
      return -1; // a comes before b
    } else if (b.slug === 'chatgpt' && a.slug !== 'gpt-4') {
      return 1; // b comes before a
    } else {
      return 0; // no change in order
    }
  };

  // Sort the assistants array based on the custom comparison function
  const sortedAssistants = assistants.sort(compareAssistants);

  return (
    <div
      className={classNames(
        'toolsMenu h-[calc(100vh-170px)] overflow-y-scroll pb-3',
        (isDesktop || isWide) && 'border-x border-gray-300',
      )}
    >
      {sortedAssistants.length ? (
        sortedAssistants.map(assistant => (
          <Item
            key={assistant.slug}
            slug={slug}
            assistant={assistant}
            onChange={onChange}
          />
        ))
      ) : (
        <p className='mt-5 flex justify-center'>No AI Assistants found</p>
      )}
    </div>
  );
};

export default Assistants;
