import React, { FC } from 'react';
import send from '@assets/send.svg';
import Textarea from '@components/inputs/Textarea';
import Loader from 'react-spinners/ClipLoader';
import { useViewport } from '@context/viewport';

type Props = {
  input: string;
  setInput: (input: string) => void;
  updateUserConversation: () => void;
  isLoading: boolean;
  readonly?: boolean;
  isAltSubmit?: boolean;
};

const ChatInput: FC<Props> = ({
  input,
  setInput,
  updateUserConversation,
  isLoading,
  readonly,
  isAltSubmit,
}) => {
  const { isMobile, isTablet } = useViewport();

  const onSend = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isMobile || isTablet) return;

    const canSubmit = input.length && !isLoading;

    if (isAltSubmit) {
      if (
        canSubmit &&
        e.key === 'Enter' &&
        (navigator.platform.includes('Mac') ? e.metaKey : e.ctrlKey)
      ) {
        e.preventDefault();
        updateUserConversation();
      }
      return;
    }

    if (canSubmit && e.key === 'Enter') {
      e.preventDefault();
      updateUserConversation();
    }
  };

  return (
    <div className='relative' style={{ paddingRight: '7em' }}>
      <Textarea
        className='py-3.5'
        onChange={e => setInput(e.target.value)}
        onKeyDown={onSend}
        value={input}
        minRows={1}
        maxRows={10}
        placeholder='Type a message...'
        readOnly={readonly}
      />
      <div
        className='absolute'
        style={{
          bottom: '1em',
          right: '0.7em',
        }}
      >
        {isLoading ? (
          <button
            type='button'
            className='rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100'
            disabled
          >
            Typing
          </button>
        ) : (
          <button
            onClick={updateUserConversation}
            disabled={!input.length}
            type='button'
            className='inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Send
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatInput;
