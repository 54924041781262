import React from 'react';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  checked: boolean;
  label?: JSX.Element | string;
};

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, id, name, checked, label }, ref) => {
    return (
      <div className='flex items-center'>
        <input
          id={id}
          type='checkbox'
          onChange={onChange}
          name={name}
          checked={checked}
          className='h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
          ref={ref}
        />
        {label && (
          <label
            htmlFor={id}
            className='ml-3 min-w-0 flex-1 select-none text-xs text-gray-600'
          >
            {label}
          </label>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
