import { CredentialResponse, useGoogleLogin } from '@react-oauth/google';
import conversionTracker from '@utils/conversionTracker';

export const onLoginSuccess = (data: CredentialResponse) => {
  const { credential } = data;

  fetch('/api/internal/google_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ credential }),
  })
    .then(res => res.json())
    .then(data => {
      const { user } = data;

      conversionTracker(window, 'sign_up', {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });

      location.reload();
    })
    .catch(error => {
      console.error('Error logging in', error);
    });
};

export const useCustomLoginSuccess = (cb?: () => void) => {
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      fetch('/api/internal/google_login_access_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access_token: tokenResponse.access_token }),
      })
        .then(res => res.json())
        .then(data => {
          const { user } = data;

          conversionTracker(window, 'sign_up', {
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
          });

          cb ? cb() : location.reload();
        })
        .catch(error => {
          console.error('Error logging in', error);
        });
    },
    onError: err => console.log(err),
  });

  return { login };
};
