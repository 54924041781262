import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAssistantApi, getAssistantsApi } from './api';

export const useAssistants = (slug: string) => {
  const [query, setQuery] = useState('');
  const [system, setSystem] = useState('');
  const { data: allAssistants, isLoading: fetchingAssistants } = useQuery({
    queryKey: ['assistants'],
    queryFn: () => getAssistantsApi(),
  });

  const { data: assistant, isLoading: fetchingAssistant } = useQuery({
    queryKey: ['assistant', slug],
    queryFn: () => getAssistantApi(slug),
    onSuccess: data => setSystem(data.assistant?.system_message.trim()),
  });

  const filteredAssistants = allAssistants?.assistants?.filter(assistant =>
    assistant.name.toLowerCase().includes(query.toLowerCase()),
  );

  const search = (input: string) => setQuery(input);
  const clearSearch = () => setQuery('');

  const isLoadingAssistants = fetchingAssistants || fetchingAssistant;

  return {
    assistants: filteredAssistants,
    assistant: assistant?.assistant,
    isLoadingAssistants,
    query,
    search,
    clearSearch,
    system,
    setSystem,
  };
};
