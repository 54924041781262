import React, { FC, Fragment, ReactNode } from 'react';
import { Transition } from '@headlessui/react';

type Props = {
  show: boolean;
  children: ReactNode;
};

const HideAndShow: FC<Props> = ({ show, children }) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter='transition-opacity ease-linear duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity ease-linear duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {children}
      </Transition.Child>
    </Transition.Root>
  );
};

export default HideAndShow;
