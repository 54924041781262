import React, { FC, useState } from 'react';
import SearchBar from './SearchBar';
import Assistants from './Assistants';
import { Assistant } from '../types';
import { Button } from '@components/button/Button';
import DialogComp from '@components/dialog/Dialog';
import CreateAssistant from '../system/CreateAssistant';
import { User } from '@/types/global';
import LoginRedirect from '../system/LoginRedirect';

type Props = {
  user?: User;
  query: string;
  search: (query: string) => void;
  clear: () => void;
  assistants: Assistant[];
  slug: string;
  onChange: (slug: string) => void;
};

const AssistantsBar: FC<Props> = ({
  user,
  query,
  search,
  clear,
  assistants,
  slug,
  onChange,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div>
      {/* <Button
        variant='default'
        onClick={() => {
          window.location.href = '/bots';
        }}
        text='All Community Bots'
        size='lg'
        className='mb-3 mt-1 w-full'
      /> */}
      <Button
        variant='badge'
        onClick={() => setOpenDialog(true)}
        text='Create Your AI Bot'
        size='lg'
        className='mb-3 mt-1 w-full'
      />
      <SearchBar query={query} search={search} clear={clear} />
      <Assistants assistants={assistants} slug={slug} onChange={onChange} />

      <DialogComp isOpen={openDialog} setIsOpen={setOpenDialog}>
        {user ? (
          <CreateAssistant closeModal={() => setOpenDialog(false)} />
        ) : (
          <LoginRedirect closeModal={() => setOpenDialog(false)} />
        )}
      </DialogComp>
    </div>
  );
};

export default AssistantsBar;
