import React, { FC, useState, useRef, useEffect } from 'react';
import Textarea from '@components/inputs/Textarea';
import Heading from '@components/typography/Heading';
import { Assistant } from '../types';
import classNames from 'classnames';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/solid';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

type Props = {
  assistant: Assistant;
  system: string;
  setSystem: (val: string) => void;
  isDesktop?: boolean;
};

const System: FC<Props> = ({ assistant, setSystem, system, isDesktop }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      setIsBlocked(
        textareaRef.current.scrollHeight > textareaRef.current.clientHeight,
      );
    }
  }, [system]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={classNames(
        'w-full rounded-md bg-white p-4',
        isDesktop && 'mx-2 h-fit shadow-md',
      )}
    >
      <div className='mb-2 flex items-center'>
        <Heading size='xs' margin='default'>
          System Prompt
        </Heading>
        <Tooltip
          title='System Prompts are used to provide context to the AI. They are the initial instructions that the AI will use to generate a response.'
          arrow={true}
          theme='light'
        >
          <InformationCircleIcon className='ml-2 h-5 w-5 text-gray-400' />
        </Tooltip>
      </div>
      <div className='relative'>
        <Textarea
          ref={textareaRef}
          className={classNames(
            'w-full',
            isDesktop
              ? 'max-h-[calc(100vh-260px)]'
              : 'max-h-[500px] min-h-[500px]',
            isExpanded && 'h-auto',
          )}
          name={assistant.slug}
          value={system}
          onChange={e => setSystem(e.target.value)}
          minRows={4}
          maxRows={isExpanded ? undefined : 8}
        />
        {isBlocked && !isExpanded && (
          <button
            onClick={toggleExpand}
            className='absolute bottom-3 right-3 text-purple-700'
          >
            <ChevronDoubleDownIcon className='h-7 w-7' />
          </button>
        )}
      </div>
    </div>
  );
};

export default System;
