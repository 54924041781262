import React, { FC, useState } from 'react';
import Heading from '@components/typography/Heading';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { User } from '@/types/global';
import { isProduction, isDevelopment } from '@utils/constants';
import { safeCredentials, handleErrors } from '@utils/fetch';
import { Button } from '@components/button/Button';

type Props = {
  closeModal: () => void;
  user: User | undefined;
};

// https://stackoverflow.com/questions/73468977/property-stripe-pricing-table-does-not-exist-on-type-jsx-intrinsicelements
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const PricingTable: FC<{ user: User | undefined }> = ({ user }) => {
  return (
    <div className='relative p-4'>
      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
        <CreditCardIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
      </div>
      <div className='mt-3 text-center sm:mt-5'>
        <Heading size='base'>Free Trial (7 Days)</Heading>
        <div className='mt-3 px-6'>
          <p className='text-sm text-gray-500'>Risk-free. Cancel anytime.</p>
        </div>
      </div>
      {isProduction && (
        <stripe-pricing-table
          pricing-table-id='prctbl_1NAmo4D4WxN8AMrhYv5nHB5n'
          publishable-key='pk_live_51Ijj4xD4WxN8AMrh2yPywu5kYcYDoqztI8np7b3h2IiQrfLpSP73DN74J7ZSXdZmNBrcG5MSLnkbVWO4x8qJMzD400bSJNtJR7'
          customer-email={user!.email}
        ></stripe-pricing-table>
      )}

      {isDevelopment && (
        <stripe-pricing-table
          pricing-table-id='prctbl_1N5RPsD4WxN8AMrhnv0Eees9'
          publishable-key='pk_test_51Ijj4xD4WxN8AMrhhpg3b2YHItGZY28jiCMlyOv020cqgtg4HRCuBRhLQTCQwAzZrZgIZskYC0rCt2ZVTlvOAvNH00eZ8ceiZE'
          customer-email={user!.email}
        ></stripe-pricing-table>
      )}
    </div>
  );
};

const StartSubscriptionNow: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleStartSubscription = async () => {
    setIsLoading(true);
    const response = await fetch(
      '/api/internal/billing/start_now',
      safeCredentials({
        method: 'POST',
      }),
    );
    const data = await response.json();
    if (response.ok) {
      window.location.href = '/chat/gpt-4?event=purchase';
    } else {
      handleErrors(data);
    }
  };

  return (
    <div className='relative p-4'>
      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
        <CreditCardIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
      </div>
      <div className='mt-3 text-center sm:mt-5'>
        <Heading size='base'>
          Begin your subscription now to continue using InputAI.
        </Heading>
        <div className='mt-3 px-6'>
          <p className='text-sm text-gray-500'>
            Your trial credits have been used up.
          </p>
        </div>
        <Button
          type='button'
          onClick={handleStartSubscription}
          text={isLoading ? 'Please wait...' : 'Start Subscription'}
          size='lg'
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

const BillingDialog: FC<Props> = ({ closeModal, user }) => {
  switch (user?.billing_status) {
    case 'no_plan':
      return <PricingTable user={user} />;
    case 'trialing':
      return <StartSubscriptionNow />;
    default:
      return null;
  }
};

export default BillingDialog;
