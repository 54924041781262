import { AlgoliaIndices } from '../hooks/useAlgolia/types';

export const isProduction = process.env.NODE_ENV === 'production';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const algoliaId = process.env.ALGOLIA_APP_ID as string;
export const algoliaKey = process.env.ALGOLIA_SEARCH_API_KEY as string;

export const toolIndexName = isDevelopment
  ? AlgoliaIndices.TOOL_DEV
  : AlgoliaIndices.TOOL;

export const assistantIndexName = isDevelopment
  ? AlgoliaIndices.ASSISTANT_DEV
  : AlgoliaIndices.ASSISTANT;

export const APP_NAME = 'InputAI';
