import React, { FC } from 'react';
import { Assistant } from '../types';
import { cn } from '@utils/helpers';

type Props = {
  assistant: Assistant;
  isMobile?: boolean;
};

const AssistantHeader: FC<Props> = ({ assistant, isMobile }) => {
  return (
    <div className={cn('d-flex align-items-center', !isMobile && 'mb-4')}>
      {assistant.icon_url && (
        <div className='rounded-circle icon text-primary flex h-[30px] w-[30px] items-center justify-center bg-white'>
          <img src={assistant.icon_url} alt='icon' />
        </div>
      )}
      <div className='ml-2 flex-1'>
        <h6 className='text-capitalize mb-1 flex items-center gap-2'>
          <b>{assistant.name}</b>
        </h6>

        <p className='text-muted mb-0 leading-none'>
          <small>{assistant.description}</small>
        </p>
      </div>
    </div>
  );
};

export default AssistantHeader;
