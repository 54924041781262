import React, { FC, useEffect, useState } from 'react';
import { User } from '@/types/global';
import Header from './header/Header';
import AssistantHeaderDesktop from './assistant-header/AssistantHeaderDesktop';
import YoutubeIntro from './youtube-intro/YoutubeIntro';
import ChatInterface from './chat-interface/ChatInterface';
import Spinner from '@components/spinner/Spinner';
import Heading from '@components/typography/Heading';
import { useViewport } from '@context/viewport';
import { useChat } from './lib/useChat';
import AssistantsBar from './assistants/AssistantsBar';
import System from './system/System';
import { useAssistants } from './lib/useAssistants';
import MobileView from './MobileView';
import queryString from 'query-string';
import CustomLogin from './custom-login/CustomLogin';
import DialogComp from '@components/dialog/Dialog';
import BillingDialog from './billing-dialog/BillingDialog';
import BillingSuccess from './billing-dialog/BillingSuccess';
import conversionTracker from '@utils/conversionTracker';

type Props = {
  user?: User;
  initialSlug: string;
};

const Chat: FC<Props> = ({ user, initialSlug }) => {
  const [slug, setSlug] = useState(initialSlug);
  const {
    assistants,
    assistant,
    query,
    search,
    clearSearch,
    system,
    setSystem,
  } = useAssistants(slug);

  const {
    messages,
    input,
    setInput,
    generateConversation,
    updateUserConversation,
    streamMessage,
    isLoading,
    stopGeneration,
    isBillingDialogOpen,
    setIsBillingDialogOpen,
  } = useChat(slug, system, assistant?.id);
  const { isMobile, isTablet } = useViewport();

  const [isBillingSuccessOpen, setIsBillingSuccessOpen] = useState(false);

  useEffect(() => {
    if (assistant) {
      const { google_login } = queryString.parse(location.search);

      if (google_login) {
        generateConversation();
        return history.replaceState({}, '', location.pathname);
      }

      const currentUrl = location.href;
      const currentSlug = currentUrl.substring(
        currentUrl.lastIndexOf('/') + 1,
        location.search ? currentUrl.indexOf('?') : undefined,
      );
      const newUrl = currentUrl.replace(currentSlug, assistant.slug);

      history.replaceState({}, '', newUrl);
    }
  }, [assistant?.slug]);

  useEffect(() => {
    const { event } = queryString.parse(location.search);

    if (event === 'purchase') {
      if (!user) return;

      // show BillingSuccess
      setIsBillingSuccessOpen(true);

      conversionTracker(window, 'purchase', {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        value: 19,
        item_id: 'lite',
        item_name: 'Lite',
        item_category: 'Subscription',
        item_variant: 'Monthly',
        price: 19,
        id: user.id,
      });

      // set timeout, reload page after 5 seconds, remove query string
      setTimeout(() => {
        history.replaceState({}, '', location.pathname);
        location.reload();
      }, 5000);
    }
  }, []);

  if (!assistants) return <Spinner title='Loading AI Assistances...' />;

  if (isMobile || isTablet)
    return (
      <MobileView
        assistant={assistant}
        user={user}
        query={query}
        search={search}
        clear={clearSearch}
        assistants={assistants}
        slug={slug}
        onChange={setSlug}
        messages={messages}
        input={input}
        setInput={setInput}
        generateConversation={generateConversation}
        updateUserConversation={updateUserConversation}
        streamMessage={streamMessage}
        isLoading={isLoading}
        system={system}
        setSystem={setSystem}
        stopGeneration={stopGeneration}
        isBillingDialogOpen={isBillingDialogOpen}
        setIsBillingDialogOpen={setIsBillingDialogOpen}
        isBillingSuccessOpen={isBillingSuccessOpen}
        setIsBillingSuccessOpen={setIsBillingSuccessOpen}
      />
    );

  return (
    <>
      <Header user={user} />
      <div className='grid-cols-16 grid h-[calc(100vh-48px)] gap-2 overflow-hidden bg-[#F7FAFC] px-4 pt-4'>
        <div className='col-span-3'>
          <AssistantsBar
            user={user}
            query={query}
            search={search}
            clear={clearSearch}
            assistants={assistants}
            slug={slug}
            onChange={setSlug}
          />
        </div>
        <div className='col-span-13 overflow-hidden'>
          {assistant ? (
            <>
              <div className='grid grid-cols-12 gap-4'>
                <div
                  className='col-span-5'
                  style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: 'calc(100vh - 100px)',
                  }}
                >
                  <AssistantHeaderDesktop assistant={assistant} user={user} />
                  <System
                    assistant={assistant}
                    system={system}
                    setSystem={setSystem}
                    isDesktop
                  />
                  <YoutubeIntro assistant={assistant} />
                  <div className='mt-1 text-center'>
                    <a
                      href='/terms'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-xs text-gray-500 hover:text-gray-700'
                    >
                      Terms and Conditions
                    </a>
                    <span className='mx-1 text-xs text-gray-500'>|</span>
                    <a
                      href='/privacy'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-xs text-gray-500 hover:text-gray-700'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div className='relative col-span-7 mx-2 mb-2 h-[calc(100vh-100px)] rounded-md bg-white p-4 shadow-md'>
                  <Heading size='sm' className='border-b border-slate-200 pb-3'>
                    Chat
                  </Heading>
                  {user ? (
                    <ChatInterface
                      assistant={assistant}
                      messages={messages}
                      input={input}
                      system={system}
                      setInput={setInput}
                      generateConversation={generateConversation}
                      updateUserConversation={updateUserConversation}
                      streamMessage={streamMessage}
                      isLoading={isLoading}
                      stopGeneration={stopGeneration}
                      user={user}
                    />
                  ) : (
                    <CustomLogin />
                  )}
                </div>
              </div>
            </>
          ) : (
            <Spinner title='Loading AI Assistant...' />
          )}
        </div>
      </div>

      <DialogComp
        isOpen={isBillingDialogOpen}
        setIsOpen={setIsBillingDialogOpen}
        width={'w-[1100px]'}
      >
        <BillingDialog
          closeModal={() => setIsBillingDialogOpen(false)}
          user={user}
        />
      </DialogComp>

      <DialogComp
        isOpen={isBillingSuccessOpen}
        setIsOpen={setIsBillingSuccessOpen}
      >
        <BillingSuccess />
      </DialogComp>
    </>
  );
};

export default Chat;
