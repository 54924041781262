import { handleErrors, safeCredentials } from '@utils/fetch';
import {
  AssistantResponse,
  AssistantsResponse,
  CreateAssistantParams,
  CreateConversationResponse,
  GenerateConversationPayload,
  GenerateConversationRequest,
  GenerateConversationRequestParams,
  UpdateConversationParams,
  UpdateConversationResponse,
} from '../types';
import { isProduction } from '@utils/constants';

export const createConversationApi = (
  assistantId: string,
): Promise<CreateConversationResponse> => {
  return fetch(
    '/api/internal/conversations',
    safeCredentials({
      method: 'POST',
      body: JSON.stringify({ assistant_id: assistantId }),
    }),
  )
    .then(handleErrors)
    .then(res => res);
};

export const generateConversationRequestApi = (
  params: GenerateConversationRequestParams,
): Promise<GenerateConversationRequest> => {
  return fetch(
    '/api/internal/gen_conversation_request',
    safeCredentials({
      method: 'POST',
      body: JSON.stringify(params),
    }),
  )
    .then(handleErrors)
    .then(res => res);
};

export const generateConversationApi = (
  url: string,
  payload: GenerateConversationPayload,
  dev_token: string,
  setStreamMessage: (message: string) => void,
  onStreamEnd: (message: string) => void,
  setSignalController: (controller: AbortController) => void,
) => {
  const controller = new AbortController();
  setSignalController(controller);

  const body = isProduction
    ? {
        log_id: payload.log_id,
      }
    : {
        ...payload,
        dev_token,
      };

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    signal: controller.signal,
  }).then(res => {
    const reader = res.body?.getReader();
    let message = '';

    const read = () => {
      // read the data
      reader?.read().then(({ done, value }) => {
        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
          return onStreamEnd(message);
        }

        const decoder = new TextDecoder();
        const text = decoder.decode(value);
        message += text;

        setStreamMessage(message);

        read();
      });
    };

    read();
  });
};

export const updateConversationApi = (
  params: UpdateConversationParams,
): Promise<UpdateConversationResponse> => {
  return fetch(
    `/api/internal/conversations/${params.id}`,
    safeCredentials({
      method: 'PUT',
      body: JSON.stringify(params),
    }),
  )
    .then(handleErrors)
    .then(res => res);
};

export const getAssistantsApi = (): Promise<AssistantsResponse> => {
  return fetch('/api/internal/assistants')
    .then(handleErrors)
    .then(res => res);
};

export const getAssistantApi = (slug: string): Promise<AssistantResponse> => {
  return fetch(`/api/internal/assistants/${slug}`)
    .then(handleErrors)
    .then(res => res);
};

export const createAssistantsApi = (
  params: CreateAssistantParams,
): Promise<AssistantResponse> => {
  return fetch(
    '/api/internal/assistants',
    safeCredentials({
      method: 'POST',
      body: JSON.stringify(params),
    }),
  )
    .then(handleErrors)
    .then(res => res);
};
