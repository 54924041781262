import React from 'react';
import ReactDOM from 'react-dom';
import Chat from './Chat';
import './chat.scss';
import QueryProvider from '@components/queryProvider/QueryProvider';
import { ViewportProvider } from '@context/viewport';
import { GoogleOAuthProvider } from '@react-oauth/google';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('params') as HTMLElement;
  const data = JSON.parse(node.getAttribute('data') || '');
  const user = node.getAttribute('data-user')
    ? JSON.parse(node.getAttribute('data-user') || '')
    : undefined;

  ReactDOM.render(
    <GoogleOAuthProvider clientId={process.env.GOOGLE_SIGN_IN_CLIENT_ID || ''}>
      <QueryProvider>
        <ViewportProvider>
          <Chat user={user} initialSlug={data.slug} />
        </ViewportProvider>
      </QueryProvider>
    </GoogleOAuthProvider>,
    document.getElementById('content'),
  );
});
