import React from 'react';
import CustomGoogleBtn from '../google-login/CustomGoogleBtn';
import GoogleLogo from '@assets/google.svg';

const CustomLogin = () => {
  return (
    <>
      <CustomGoogleBtn />
      <small className='mx-auto mt-2 flex flex-wrap items-center justify-center gap-1 font-bold'>
        <div className='flex items-center'>
          <span>You will be asked to Sign in with Google</span>
          <img className='ml-1 inline-block h-3 w-3' src={GoogleLogo}></img>
          <span>.</span>
        </div>
        <div>
          <span>Or, </span>{' '}
          <a href='/login' style={{ textDecoration: 'underline' }}>
            Sign in with your email address.
          </a>
        </div>
      </small>
    </>
  );
};

export default CustomLogin;
