import { useEffect, useState } from 'react';
import {
  GenerateConversationRequestParams,
  Message,
  UpdateConversationConversation,
  UpdateConversationParams,
} from '../types';
import { useMutation } from '@tanstack/react-query';
import {
  createConversationApi,
  generateConversationApi,
  generateConversationRequestApi,
  updateConversationApi,
} from './api';

export const useChat = (slug: string, system: string, assistantId?: string) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [streamMessage, setStreamMessage] = useState('');
  const [input, setInput] = useState('Go!');
  const [isLoading, setIsLoading] = useState(false);
  const [isBillingDialogOpen, setIsBillingDialogOpen] = useState(false);
  const [signalController, setSignalController] =
    useState<AbortController | null>(null);

  const systemMessage: Message = {
    role: 'system',
    content: system,
  };

  const newUserMessage: Message = {
    role: 'user',
    content: input,
  };

  useEffect(() => {
    if (signalController) {
      // Abort the streaming when changing assistant & reset states
      signalController.abort();

      setMessages([]);
      setStreamMessage('');
      setInput('Go!');
      setIsLoading(false);
    }
  }, [slug]);

  const { mutateAsync: createConversation, data: initialConversation } =
    useMutation({
      mutationFn: (assistantId: string) => createConversationApi(assistantId),
    });

  const { mutate: generateConversationRequest, error: generateRequestError } =
    useMutation({
      mutationFn: (params: GenerateConversationRequestParams) =>
        generateConversationRequestApi(params),
      onSuccess: data => {
        // ask AI & update conversation
        generateConversationApi(
          data.url,
          data.payload,
          data.dev_token || '',
          setStreamMessage,
          onStreamEnd,
          setSignalController,
        );
      },
      onError: () => onRequestError(),
    });

  const onRequestError = () => {
    setMessages([]);
    setStreamMessage('');
    setIsLoading(false);

    setIsBillingDialogOpen(true);
  };

  const onStreamEnd = (message: string) => {
    const newMessage: Message = {
      role: 'assistant',
      content: message,
    };

    updateMessages(message);
    setStreamMessage('');
    updateBotConversation({
      messages: [...messages, newMessage],
    });
    setIsLoading(false);
  };

  const { mutate: updateConversationMutation } = useMutation({
    mutationFn: (params: UpdateConversationParams) =>
      updateConversationApi(params),
    onSuccess: data => {
      const lastMessage =
        data.conversation?.messages[data.conversation?.messages?.length - 1];

      // if user input, update conversation API & ask AI
      if (lastMessage?.role === 'user') {
        updateMessages(input, true);
        generateConversationRequest({
          id: data.conversation.id,
        });
        setInput('');
      }
    },
  });

  const generateConversation = async () => {
    setIsLoading(true);

    const { conversation } = await createConversation(assistantId || '');

    updateConversationMutation({
      id: conversation.id,
      conversation: {
        messages: [systemMessage, newUserMessage],
      },
    });
  };

  const updateBotConversation = (
    conversation: UpdateConversationConversation,
  ) => {
    setIsLoading(true);

    updateConversationMutation({
      id: initialConversation?.conversation.id || '',
      conversation,
    });
  };

  const updateUserConversation = () => {
    setIsLoading(true);

    const conversation = [systemMessage, ...messages, newUserMessage];

    return updateConversationMutation({
      id: initialConversation?.conversation.id || '',
      conversation: {
        messages: conversation,
      },
    });
  };

  const updateMessages = (message: string, fromUser?: boolean) =>
    setMessages(prev => [
      ...prev,
      {
        content: message,
        role: fromUser ? 'user' : 'assistant',
      },
    ]);

  const stopGeneration = () => {
    if (signalController) {
      signalController.abort();
      onStreamEnd(streamMessage);
    }
  };

  return {
    messages,
    system,
    input,
    setInput,
    generateConversation,
    updateUserConversation,
    streamMessage,
    isLoading: isLoading || !!streamMessage,
    stopGeneration,
    generateRequestError,
    isBillingDialogOpen,
    setIsBillingDialogOpen,
  };
};
