import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
  ReactNode,
  FC,
} from 'react';
import debounce from 'lodash/debounce';
import { ViewportInterface } from './types';
import { breakpoints } from './constants';

const ViewportStore = createContext<ViewportInterface>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isWide: false,
  width: 0,
});

export const useViewport = (): ViewportInterface =>
  useContext<ViewportInterface>(ViewportStore);

interface ViewportProps {
  children: ReactNode;
}

const { Provider } = ViewportStore;

export const ViewportProvider: FC<ViewportProps> = ({
  children,
}: ViewportProps) => {
  const [width, setWidth] = useState<number>(0);

  const handleWindowWidthChange = useCallback(
    debounce(() => {
      setWidth(window.innerWidth);
    }, 100),
    [],
  );

  useEffect(() => {
    handleWindowWidthChange();
    window.addEventListener('resize', handleWindowWidthChange);

    return () => {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  }, []);

  const viewport = {
    width,
    isMobile: width < breakpoints.mobile,
    isTablet: width >= breakpoints.mobile && width < breakpoints.tablet,
    isDesktop: width >= breakpoints.tablet && width < breakpoints.desktop,
    isWide: width >= breakpoints.desktop,
  };

  return <Provider value={viewport}>{children}</Provider>;
};
