import React, { FC } from 'react';
import { Assistant } from '../types';
import Heading from '@components/typography/Heading';

type Props = {
  assistant: Assistant;
};

const YoutubeIntro: FC<Props> = ({ assistant }) => {
  if (!assistant.youtube_video_url) {
    return null;
  }

  return (
    <div className='mx-2 mt-3 h-fit w-full rounded-md bg-white p-4 shadow-md'>
      <Heading size='xs' margin='sm'>
        Demo Video
      </Heading>

      <div
        style={{
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          src={assistant.youtube_video_url}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default YoutubeIntro;
