import React, { FC } from 'react';
import Heading from '@components/typography/Heading';
import { KeyIcon } from '@heroicons/react/24/outline';
import { Button } from '@components/button/Button';
import { useCustomLoginSuccess } from '@components/google-login/helpers';

type Props = {
  closeModal: () => void;
};

const LoginRedirect: FC<Props> = ({ closeModal }) => {
  const { login } = useCustomLoginSuccess();

  return (
    <div className='relative p-4'>
      <div>
        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100'>
          <KeyIcon className='h-6 w-6 text-yellow-600' aria-hidden='true' />
        </div>
        <div className='mt-3 text-center sm:mt-5'>
          <Heading size='base'>Please sign in</Heading>
          <div className='mt-3'>
            <p className='text-sm text-gray-500'>
              You have to sign in with Google to continue using InputAI.
            </p>
          </div>
        </div>
      </div>
      <div className='mt-3 flex gap-4'>
        <Button
          onClick={closeModal}
          variant='outline'
          size='md'
          className='w-full'
          text='Cancel'
        />
        <Button
          type='submit'
          onClick={() => login()}
          size='md'
          className='w-full'
          text='Sign in with Google'
        />
      </div>
    </div>
  );
};

export default LoginRedirect;
