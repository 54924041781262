import React, { FC } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { onLoginSuccess } from './helpers';

const GoogleBtn: FC = () => {
  return (
    <GoogleLogin
      onSuccess={onLoginSuccess}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  );
};

export default GoogleBtn;
