import React, { FC } from 'react';
import Heading from '@components/typography/Heading';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

type Props = {};

const BillingSuccess: FC<Props> = () => {
  return (
    <div className='relative p-4'>
      <div>
        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
          <CheckCircleIcon
            className='h-6 w-6 text-green-600'
            aria-hidden='true'
          />
        </div>
        <div className='mt-3 text-center sm:mt-5'>
          <Heading size='base'>
            Success! Please wait for a few seconds...
          </Heading>
          <div className='mt-3 px-6'>
            <p className='text-sm text-gray-500'>
              Please wait while we set up your account. This won't take long.
              You will be redirected in 5 seconds.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingSuccess;
