import React, { FC } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';

type SpinnerTypes = 'clip' | 'default';

type Props = {
  type?: SpinnerTypes;
  title?: string;
  subtitle?: string;
  color?: string;
  size?: number;
};

const Spinner: FC<Props> = ({ type, title, subtitle, color, size }) => {
  const spinnerProps = {
    color: color || '#9597ff',
    size: size || 40,
  };

  const getLoader = () => {
    switch (type) {
      case 'clip':
        return <ClipLoader {...spinnerProps} />;
      default:
        return <PropagateLoader {...spinnerProps} />;
    }
  };

  return (
    <div className='mt-28 flex flex-col items-center justify-center gap-1'>
      {getLoader()}
      <h2 className='ml-5 mt-24 text-xl font-semibold text-gray-700'>
        {title}
      </h2>
      <h3 className='font-base ml-5 text-lg text-gray-500'>{subtitle}</h3>
    </div>
  );
};

export default Spinner;
