import { sha256 } from 'js-sha256';

const createUserData = (email, firstName, lastName) => {
  return {
    em: sha256(email),
    fn: sha256(firstName),
    ln: sha256(lastName),
    email_address: sha256(email),
    address: {
      first_name: sha256(firstName),
      last_name: sha256(lastName),
    },
  };
};

const createEcommerceItem = ({
  item_id,
  item_name,
  item_category,
  item_variant,
  price,
}) => {
  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price,
    quantity: 1,
  };
};

const pushDataLayer = (window, eventName, userData, ecommerceData = {}) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: eventName,
    user_data: userData,
    ecommerce: ecommerceData,
  });

  if (process.env.NODE_ENV !== 'production') {
    console.log('dataLayer', window.dataLayer);
  }
};

const conversionTracker = (window, event, options = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(event, options);
  }

  if (window.dataLayer) {
    const {
      email,
      first_name,
      last_name,
      value,
      item_id,
      item_name,
      item_category,
      item_variant,
      price,
    } = options;
    const userData = createUserData(email, first_name, last_name);

    switch (event) {
      case 'view_item':
        pushDataLayer(window, event, userData);
        break;
      case 'sign_up':
        pushDataLayer(window, event, userData);
        break;
      // case 'add_payment_info':
      //   pushDataLayer(window, event, userData, {
      //     payment_type: 'Credit Card',
      //     currency: 'USD',
      //     value,
      //     items: [
      //       createEcommerceItem({
      //         item_id,
      //         item_name,
      //         item_category,
      //         item_variant,
      //         price,
      //       }),
      //     ],
      //   });
      //   break;
      case 'purchase':
        pushDataLayer(window, event, userData, {
          transaction_id: options.id,
          currency: 'USD',
          value,
          items: [
            createEcommerceItem({
              item_id,
              item_name,
              item_category,
              item_variant,
              price,
            }),
          ],
        });
        break;
      default:
    }
  }
};

export default conversionTracker;
