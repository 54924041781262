export const inputStyles =
  'mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

export const inputErrorStyles = 'border-red-400';

export const focusOutline =
  'focus:outline-offset-2 focus:outline-indigo-500 focus:ring-indigo-500';

export const lightIndigo = '#7476E7';
export const darkIndigo = '#4338CA';
