import React, { ForwardedRef } from 'react';
import ScrollableContainer from '@components/scrollable/ScrollableContainer';
import { Message } from '../types';
import Paragraph from '@components/typography/Paragraph';
import classNames from 'classnames';
import { User } from '@/types/global';
import { Assistant } from '../types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@components/button/Button';
import { ClipboardIcon } from '@heroicons/react/24/outline';

import styles from './Messages.module.scss';

interface Props {
  assistant: Assistant;
  messages: Message[];
  lastMessageRef?: ForwardedRef<HTMLDivElement>;
  isSmallScreen?: boolean;
  btnTop: boolean;
  user?: User;
  isLoading?: boolean;
  isStreaming?: boolean;
}

const LoadingBar = () => (
  <div className='flex flex-col gap-2'>
    <div className='flex animate-pulse space-x-4'>
      <div className='flex-1 space-y-4 py-1'>
        <div className='space-y-2'>
          <div className='h-4 rounded bg-slate-200'></div>
          <div className='h-4 w-5/6 rounded bg-slate-200'></div>
        </div>
      </div>
    </div>
  </div>
);

const Messages = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      assistant,
      messages,
      lastMessageRef,
      isSmallScreen,
      btnTop,
      user,
      isLoading,
      isStreaming,
    },
    ref,
  ) => {
    let userName: any;
    if (user?.profile_picture_url) {
      userName = (
        <div className='flex items-center'>
          <img
            className='inline-block h-6 w-6 rounded-full'
            src={user!.profile_picture_url || ''}
          />
          <span className='ml-2'>{user.first_name}</span>
        </div>
      );
    } else {
      userName = user!.first_name + ' (You)';
    }

    const assistantName = (
      <div className='flex items-center'>
        <div
          className='bg-light rounded-circle text-primary h-6 w-6'
          style={{
            backgroundImage: `url(${
              assistant?.icon_url ??
              'https://res.cloudinary.com/drrk2vuyp/image/upload/c_scale,w_50/v1686118314/chatgpt_e7bogd.png'
            })`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '150%',
            flexShrink: 0,
          }}
        />
        <span className='ml-2'>{assistant?.name}</span>
      </div>
    );

    const renderMarkdown = (content: string) => {
      return (
        <ReactMarkdown
          children={content.trim()}
          components={{
            ol({ node, ...props }) {
              return <ol {...props} className={styles.listDecimal} start={1} />;
            },
            ul({ node, ...props }) {
              return <ul {...props} className={styles.listDisc} />;
            },
            li({ node, ...props }) {
              return <li {...props} className={styles.listItem} />;
            },
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              const [isCopied, setIsCopied] = useState(false);

              const handleCopy = () => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 5000);
              };

              return !inline ? (
                <div className='relative'>
                  <SyntaxHighlighter
                    {...props}
                    children={String(children).replace(/\n$/, '')}
                    style={tomorrow}
                    language={match?.[1]}
                    PreTag='div'
                    wrapLines={true}
                    wrapLongLines={true}
                  />
                  <CopyToClipboard text={String(children)}>
                    <div className='absolute bottom-3 right-3'>
                      <Button
                        onClick={handleCopy}
                        variant='outline'
                        className='p-1 text-gray-500 hover:text-gray-700 focus:outline-none'
                      >
                        {isCopied ? (
                          'Copied!'
                        ) : (
                          <>
                            <ClipboardIcon className='mr-1 inline-block h-6 w-6' />
                            <span className='inline-block'>Copy</span>
                          </>
                        )}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </div>
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            },
          }}
          remarkPlugins={[remarkGfm]}
        />
      );
    };

    if (isSmallScreen) {
      return (
        <ScrollableContainer
          ref={ref}
          classNames={classNames('-mr-6 pr-3', !btnTop && 'flex-1')}
        >
          {messages?.map((message, i) => (
            <div
              key={`message-${i}`}
              ref={i === messages.length - 1 ? lastMessageRef : null}
              className='flex flex-col gap-2 border-b-2 border-gray-200 p-4'
            >
              <Paragraph size='xs' className='font-bold'>
                {message.role === 'assistant' ? assistantName : userName}
              </Paragraph>
              <Paragraph size='sm' className='whitespace-pre-wrap'>
                {renderMarkdown(message.content)}
              </Paragraph>
            </div>
          ))}

          {isLoading &&
            !isStreaming &&
            messages.length > 0 &&
            messages.length % 2 === 0 && (
              <div className='flex flex-col gap-2 border-b-2 border-gray-200 p-4'>
                <Paragraph size='xs' className='font-bold'>
                  {userName}
                </Paragraph>
                <Paragraph size='sm' className='whitespace-pre-wrap'>
                  <LoadingBar />
                </Paragraph>
              </div>
            )}

          {isLoading && !isStreaming && messages.length % 2 === 1 && (
            <div className='flex flex-col gap-2 border-b-2 border-gray-200 p-4'>
              <Paragraph size='xs' className='font-bold'>
                {assistantName}
              </Paragraph>
              <Paragraph size='sm' className='whitespace-pre-wrap'>
                <LoadingBar />
              </Paragraph>
            </div>
          )}
        </ScrollableContainer>
      );
    }

    return (
      <ScrollableContainer
        ref={ref}
        classNames={classNames('-mr-6 pr-3', !btnTop && 'flex-1')}
        offset
      >
        {messages?.map((message, i) => (
          <div
            key={`message-${i}`}
            ref={i === messages.length - 1 ? lastMessageRef : null}
            className='grid grid-cols-12 border-b-2 border-gray-200 p-4'
          >
            <Paragraph size='sm' className='col-span-2 font-semibold'>
              {message.role === 'assistant' ? assistantName : userName}
            </Paragraph>
            <Paragraph
              size='sm'
              className='col-span-10 ml-8 whitespace-pre-wrap'
            >
              {renderMarkdown(message.content)}
            </Paragraph>
          </div>
        ))}

        {isLoading &&
          !isStreaming &&
          messages.length > 0 &&
          messages.length % 2 === 0 && (
            <div className='grid grid-cols-12 border-b-2 border-gray-200 p-4'>
              <Paragraph size='sm' className='col-span-2 font-semibold'>
                {userName}
              </Paragraph>
              <Paragraph
                size='sm'
                className='col-span-10 ml-8 whitespace-pre-wrap'
              >
                <LoadingBar />
              </Paragraph>
            </div>
          )}

        {isLoading && !isStreaming && messages.length % 2 === 1 && (
          <div className='grid grid-cols-12 border-b-2 border-gray-200 p-4'>
            <Paragraph size='sm' className='col-span-2 font-semibold'>
              {assistantName}
            </Paragraph>
            <Paragraph
              size='sm'
              className='col-span-10 ml-8 whitespace-pre-wrap'
            >
              <LoadingBar />
            </Paragraph>
          </div>
        )}
      </ScrollableContainer>
    );
  },
);

Messages.displayName = 'Messages';

export default Messages;
