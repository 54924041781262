import React, { FC, useState } from 'react';
import { User } from '../../../types/global';
import BillingStatus from '@dashboard/writingTools/header/BillingStatus';
import UserDropdown from '@components/user-dropdown/UserDropdown';
import GoogleBtn from '@components/google-login/GoogleBtn';

type Props = {
  user?: User;
};

const Header: FC<Props> = ({ user }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <nav className='flex h-12 items-center justify-between border-b border-gray-200 bg-white px-3'>
      <div className='position-relative flex-grow-1'>
        <img
          src='/logo2.png'
          width='140px'
          height='auto'
          className='inline-block'
        />
        <span className='inline-flex items-center text-sm font-medium text-gray-900'>
          <span className='mr-1.5 flex h-2.5 w-2.5 flex-shrink-0 rounded-full bg-indigo-500'></span>
          Join 10,000+ others
        </span>
      </div>

      <div className='flex items-center gap-4'>
        {user ? (
          <>
            <BillingStatus status={user.billing_status} />
            {user.available_messages > 0 ? (
              <span
                className='inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800'
                onMouseOver={handleHover}
                onMouseOut={handleMouseOut}
              >
                <span className='mr-1 h-2 w-2 rounded-full bg-green-500'></span>
                {isHovered
                  ? `~ ${user.available_messages} out of ${user.total_messages} Messages Left`
                  : `~ ${user.available_messages} Messages Left`}
              </span>
            ) : (
              <span className='mr-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800'>
                <span className='mr-1 h-2 w-2 rounded-full bg-red-500'></span>
                {user.available_messages} Messages Left
              </span>
            )}
            <UserDropdown user={user} />
          </>
        ) : (
          <GoogleBtn />
        )}
      </div>
    </nav>
  );
};

export default Header;
