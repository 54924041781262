import { RefinementListProps } from 'react-instantsearch-hooks-web/dist/es/ui/RefinementList';

export enum AlgoliaIndices {
  TOOL = 'Tool_production',
  TOOL_DEV = 'Tool_development',
  ASSISTANT_DEV = 'Assistant_development',
  ASSISTANT = 'Assistant_production',
}

export type Filter = {
  key: string;
  label: string;
  items: RefinementListProps['items'];
  refine: (value: string) => void;
  clear: () => void;
  eventName: AlgoliaInsightsEventName;
};

export enum AlgoliaInsightsFilters {
  categories = 'categories',
  types = 'types',
}

export enum AlgoliaInsightsType {
  CLICK_TOOL = 'clickedObjectIDsAfterSearch',
  COVERT_TOOL = 'convertedObjectIDsAfterSearch',
  CLICK_FILTER = 'clickedFilters',
  CONVERT_FILTER = 'convertedFilters',
}

export enum AlgoliaInsightsEventName {
  TOOL_CLICK = 'Tool visited',
  TOOL_CONVERT = 'Tool used',
  CATEGORY_CLICK = 'Category selected',
  CATEGORY_CONVERT = 'Category converted',
  TAG_CLICK = 'Tag selected',
  TAG_CONVERT = 'Tag converted',
  TYPE_CLICK = 'Type selected',
  TYPE_CONVERT = 'Type converted',
}

export type AlgoliaInsightsPayload = {
  eventName: AlgoliaInsightsEventName;
  index?: AlgoliaIndices;
  queryID?: string;
  objectIDs?: string[];
  positions?: number[];
  filters?: string[];
};
