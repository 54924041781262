import React, { FC } from 'react';
import SlideIn from '@components/animations/SlideIn';
import AssistantsBar from '../assistants/AssistantsBar';
import { Assistant } from '../types';
import UserDropdown from '@components/user-dropdown/UserDropdown';
import { User } from '@/types/global';
import GoogleBtn from '@components/google-login/GoogleBtn';

type Props = {
  user?: User;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  query: string;
  search: (query: string) => void;
  clear: () => void;
  assistants: Assistant[];
  slug: string;
  onChange: (slug: string) => void;
};

const SideBar: FC<Props> = ({
  user,
  isOpen,
  setIsOpen,
  query,
  search,
  clear,
  assistants,
  slug,
  onChange,
}) => {
  return (
    <SlideIn isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex items-center justify-between'>
        <div className='position-relative flex-grow-1'>
          <img src='/logo2.png' width='140px' height='auto'></img>
        </div>
        {user ? <UserDropdown user={user} /> : <GoogleBtn />}
      </div>

      <AssistantsBar
        query={query}
        search={search}
        clear={clear}
        assistants={assistants}
        slug={slug}
        onChange={(slug: string) => {
          onChange(slug);
          setIsOpen(false);
        }}
        user={user}
      />
    </SlideIn>
  );
};

export default SideBar;
