import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Avatar } from 'antd';
import classNames from 'classnames';
import { User } from '@/types/global';
import { dropdownItems } from './helpers';
import { safeCredentials, handleErrors } from '@utils/fetch';
import Caption from '@components/typography/Caption';
import { CreditCardIcon } from '@heroicons/react/24/outline';

type Props = {
  user: User;
};

const iconStyles = 'mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500';

const UserDropdown: FC<Props> = ({ user }) => {
  const manageBilling = () => {
    fetch(`/api/internal/billing/open_stripe_portal`, safeCredentials({}))
      .then(handleErrors)
      .then(data => {
        window.open(data.session.url, '_blank');
      });
  };

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex w-full items-center justify-center gap-x-1.5 rounded-md px-3 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-50 hover:shadow-sm'>
          {!user.profile_picture_url && (
            <Avatar className='bg-primary' size={28}>
              {user.first_name[0]}
            </Avatar>
          )}

          {user.profile_picture_url && (
            <img
              className='inline-block h-8 w-8 rounded-full'
              src={user.profile_picture_url}
              alt={user.first_name}
            />
          )}

          <small className='ml-2'>
            <Caption className='font-semibold' variant='black'>
              {user.first_name}
            </Caption>
          </small>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='border-b border-gray-100 px-4 py-3'>
            <p className='text-sm'>Signed in as</p>
            <p className='mb-0 truncate text-sm font-medium text-gray-900'>
              {user.email}
            </p>
          </div>
          <div className='py-1'>
            <Menu.Item>
              <button
                onClick={manageBilling}
                className='group flex items-center px-4 py-2 text-sm'
              >
                <CreditCardIcon className={iconStyles} />
                Manage billing
              </button>
            </Menu.Item>

            {dropdownItems.map(item => (
              <Menu.Item key={item.label}>
                <a
                  href={item.link}
                  className={classNames(
                    'group flex items-center px-4 py-2 text-sm',
                    item.divider && 'border-t border-gray-100 py-3',
                  )}
                >
                  {item.icon}
                  {item.label}
                </a>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserDropdown;
