import { useMutation } from '@tanstack/react-query';
import { CreateAssistantParams, FormField } from '../types';
import * as Yup from 'yup';
import { createAssistantsApi } from './api';

export const useFormikHelper = () => {
  const initialValues = {
    name: '',
    description: '',
    system_message: `You are an assistant that helps me ...

Requirements:
- Please strictly wait for my reply at each step before proceeding.
- If the step provides options to me, strictly provide a numbered list of 5 options, and also prompt me for my own input.

You will complete the following tasks one by one, strictly wait for my reply at each step:
- Step 1: ...`,
  };

  const fields: FormField[] = [
    {
      label: 'Name',
      name: 'name',
      placeholder: 'Blog Post Assistant',
      type: 'input',
      required: true,
    },
    {
      label: 'Description',
      name: 'description',
      placeholder: 'Generate a blog with 1,000 words in 60 seconds',
      type: 'input',
      required: false,
    },
    {
      label: 'System Prompt',
      name: 'system_message',
      placeholder: 'Enter your assistant initial instructions',
      type: 'textarea',
      minRow: 10,
      maxRow: 10,
      required: true,
    },
  ];

  const validationSchema = getValidationSchema(fields);

  const { mutateAsync: createAssistant } = useMutation({
    mutationFn: async (params: CreateAssistantParams) =>
      createAssistantsApi(params),
  });

  return {
    initialValues,
    fields,
    validationSchema,
    createAssistant,
  };
};

const getValidationSchema = (fields: FormField[]) => {
  const schema: { [key: string]: Yup.StringSchema } = {};

  fields.forEach(({ name, label, required }) => {
    if (required) {
      schema[name] = Yup.string().required(`${label} is required`);
    }
  });

  return Yup.object().shape(schema);
};
