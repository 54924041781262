import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import Loader from 'react-spinners/ClipLoader';
import { cn } from '@utils/helpers';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-base font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none leading-4 shadow-sm',
  {
    variants: {
      variant: {
        default: 'bg-indigo-600 text-white hover:bg-indigo-500',
        outline:
          'bg-[#fff] text-gray-900 ring-1 focus:ring-offset-2 ring-gray-300 hover:bg-gray-50',
        ghost:
          'bg-transparent hover:bg-indigo-100 data-[state=open]:bg-transparent',
        link: 'bg-transparent underline-offset-4 hover:underline text-indigo-900 hover:bg-transparent',
        badge: 'bg-indigo-100 text-indigo-700 hover:bg-indigo-50',
      },
      size: {
        default: 'px-2 py-1 text-sm',
        xs: 'px-1.5 py-0.5 text-xs',
        sm: 'px-2 py-1 rounded-md font-medium text-base leading-5',
        md: 'rounded-md px-3 py-2.5 text-sm',
        lg: 'px-2.5 py-2 rounded-md font-medium text-lg leading-6',
        full: 'w-full px-3 py-2.5 font-medium text-xl leading-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  text?: string;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, text, children, variant, isLoading, size, ...props }, ref) => {
    return (
      <button
        type='button'
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isLoading}
        {...props}
      >
        {isLoading ? (
          <Loader className='mr-2 h-4 w-4 animate-spin' color='#444' />
        ) : (
          text
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
