import React, { FC } from 'react';
import classNames from 'classnames';
import { Assistant } from '../types';

type Props = {
  slug: string;
  assistant: Assistant;
  onChange: (slug: string) => void;
};

const Item: FC<Props> = ({ slug, assistant, onChange }) => {
  return (
    <div
      id='assistants_menu'
      className={classNames(
        'tool mb-1 flex flex-col justify-center gap-4 p-2',
        slug === assistant.slug && 'active shadow-sm',
      )}
      onClick={() => onChange(assistant.slug)}
    >
      <div className='flex items-center justify-start gap-2 '>
        <div className='rounded-circle icon text-primary'>
          <div
            className='bg-light rounded-circle text-primary h-4 w-4'
            style={{
              backgroundImage: `url(${
                assistant.icon_url ||
                'https://res.cloudinary.com/drrk2vuyp/image/upload/c_scale,w_50/v1686118314/chatgpt_e7bogd.png'
              })`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '150%',
              flexShrink: 0,
            }}
          />
        </div>
        <p className='text-capitalize mb-0 truncate'>{assistant.name}</p>
      </div>
    </div>
  );
};

export default Item;
