import React, { FC, Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Props = {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  position?: 'left' | 'right';
  children: ReactNode;
};

const SlideIn: FC<Props> = ({
  isOpen,
  setIsOpen,
  position = 'left',
  children,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10 lg:hidden' onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom={
              position === 'left' ? '-translate-x-full' : 'translate-x-full'
            }
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo={
              position === 'left' ? '-translate-x-full' : 'translate-x-full'
            }
          >
            <Dialog.Panel className='relative mr-auto flex h-screen w-full max-w-xs flex-col gap-4 overflow-y-hidden bg-white px-1 pt-4 shadow-xl'>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideIn;
