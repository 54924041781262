import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Message } from '../types';
import ChatInput from './ChatInput';
import { useInView } from 'react-intersection-observer';
import {
  ArrowDownCircleIcon,
  StopCircleIcon,
} from '@heroicons/react/24/outline';
import HideAndShow from '@components/animations/HideAndShow';
import { useViewport } from '@context/viewport';
import { cx } from 'class-variance-authority';
import { lightIndigo } from '@utils/tailwindStyles';
import Messages from './Messages';
import { Button } from '@components/button/Button';
import Checkbox from '@components/checkbox/Checkbox';
import { User } from '@/types/global';
import { Assistant } from '../types';
import Cookies from 'js-cookie';

type Props = {
  assistant: Assistant;
  messages: Message[];
  input: string;
  setInput: (input: string) => void;
  generateConversation: () => void;
  updateUserConversation: () => void;
  streamMessage: string;
  system: string;
  isLoading: boolean;
  stopGeneration: () => void;
  user?: User;
};

const ChatInterface: FC<Props> = ({
  assistant,
  messages,
  input,
  setInput,
  generateConversation,
  updateUserConversation,
  streamMessage,
  system,
  isLoading,
  stopGeneration,
  user,
}) => {
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const { isMobile, isTablet } = useViewport();
  const isSmallScreen = isMobile || isTablet;

  const [lastMessageRef, inView, entry] = useInView({
    threshold: 0.8,
  });

  const [altSubmit, setAltSubmit] = useState(() => {
    const altSubmitCookie = Cookies.get('altSubmit');
    return altSubmitCookie ? JSON.parse(altSubmitCookie) : false;
  });

  useEffect(() => {
    Cookies.set('altSubmit', altSubmit, { expires: 36500 });
  }, [altSubmit]);

  const handleAltSubmitChange = () => {
    setAltSubmit((prev: boolean) => !prev);
  };

  const isBottom = useMemo(() => {
    const scrollTop = chatContainerRef.current?.scrollTop || 0;
    const scrollHeight = chatContainerRef.current?.scrollHeight || 0;
    const clientHeight = chatContainerRef.current?.clientHeight || 0;
    const isBottom = scrollHeight - scrollTop - clientHeight <= 40;

    return isBottom;
  }, [entry?.target, messages.length, streamMessage]);

  useEffect(() => {
    const isStreaming = streamMessage && entry?.target;

    if (isStreaming && (inView || isBottom)) {
      scrollLastMessageIntoView();
    }
  }, [streamMessage]);

  useEffect(() => {
    if (inView || isBottom) {
      chatContainerRef.current?.scrollTo({
        top: chatContainerRef.current?.scrollHeight,
      });
    }
  }, [messages.length]);

  const scrollLastMessageIntoView = useCallback(() => {
    const lastMessage = entry?.target;

    if (lastMessage) {
      lastMessage.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [entry?.target]);

  const messagesWithStream = [
    ...messages,
    streamMessage && {
      role: 'assistant',
      content: streamMessage,
    },
  ].filter(Boolean) as Message[];

  return (
    <div
      className={cx(
        'flex flex-col',
        isSmallScreen ? 'h-[calc(100svh-100px)]' : 'h-[calc(100vh-190px)]', // https://stackoverflow.com/a/75648985
        messagesWithStream.length ? 'justify-between' : 'mt-4',
      )}
    >
      <Messages
        ref={chatContainerRef}
        assistant={assistant}
        messages={messagesWithStream}
        lastMessageRef={lastMessageRef}
        isSmallScreen={isSmallScreen}
        btnTop={!messagesWithStream.length}
        user={user}
        isLoading={isLoading}
        isStreaming={!!streamMessage}
      />
      {messagesWithStream.length ? (
        <div className='relative'>
          <ChatInput
            input={input}
            setInput={setInput}
            updateUserConversation={updateUserConversation}
            isLoading={isLoading}
            isAltSubmit={altSubmit}
          />
          {!!streamMessage && (
            <Button
              onClick={stopGeneration}
              variant='outline'
              className='absolute -top-8 right-0 flex cursor-pointer items-center gap-1 bg-gray-800 text-white hover:bg-gray-700 dark:bg-gray-900 dark:hover:bg-gray-700'
            >
              <StopCircleIcon width={20} color='white' />
              <span className='text-xs'>Stop Generating</span>
            </Button>
          )}
        </div>
      ) : (
        <Button
          disabled={!system}
          isLoading={isLoading}
          type='button'
          onClick={() => generateConversation()}
          text='Start Conversation'
          size='full'
        />
      )}
      <HideAndShow
        show={!inView && !isBottom && messagesWithStream?.length > 2}
      >
        <ArrowDownCircleIcon
          className='absolute bottom-20 left-1/2 -translate-x-1/2 cursor-pointer'
          onClick={scrollLastMessageIntoView}
          width={40}
          color={lightIndigo}
        />
      </HideAndShow>

      {!isSmallScreen && !!messagesWithStream.length && (
        <div className='mt-2'>
          <Checkbox
            id='altsubmit-checkbox'
            name='altsubmit-checkbox'
            checked={!!altSubmit}
            onChange={handleAltSubmitChange}
            label={
              <>
                Use{' '}
                <span className='text-gray-400'>
                  {navigator.platform.includes('Mac') ? '⌘' : 'ctrl'}
                </span>{' '}
                + ENTER to Send
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default ChatInterface;
