import React, { FC } from 'react';
import { FormField } from '../types';
import { useFormikHelper } from '../lib/form.helpers';
import { useFormik } from 'formik';
import Input from '@components/inputs/Input';
import Textarea from '@components/inputs/Textarea';
import Heading from '@components/typography/Heading';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@components/button/Button';
import Loader from 'react-spinners/ClipLoader';
import Badge from '@components/Badge';

type Props = {
  closeModal: () => void;
};

const CreateAssistant: FC<Props> = ({ closeModal }) => {
  const { initialValues, fields, validationSchema, createAssistant } =
    useFormikHelper();

  const {
    values,
    handleChange,
    errors,
    isValid,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async values => {
      const params = { ...values, model: 'gpt-3.5-turbo-0613' };
      const { assistant } = await createAssistant(params);

      if (assistant) {
        location.replace(`/chat/${assistant.slug}`);
      }
    },
  });

  const renderField = (field: FormField) => {
    const variant =
      errors[field.name as keyof typeof values] &&
      touched[field.name as keyof typeof values]
        ? 'error'
        : 'default';

    switch (field.type) {
      case 'input':
        return (
          <Input
            name={field.name}
            placeholder={field.placeholder}
            value={values[field.name as keyof typeof values]}
            onChange={handleChange}
            onBlur={handleBlur}
            variant={variant}
          />
        );
      case 'textarea':
        return (
          <Textarea
            variant={variant}
            placeholder={field.placeholder}
            name={field.name}
            value={values[field.name as keyof typeof values]}
            onChange={handleChange}
            onBlur={handleBlur}
            minRows={field.minRow}
            maxRows={field.maxRow}
          />
        );
    }
  };

  return (
    <div className='relative p-4'>
      {isSubmitting && (
        <div className='absolute inset-0 z-10 flex items-center justify-center'>
          <div className='absolute inset-0 bg-[#ebebeb] opacity-30' />
          <Loader color='#B4B4B4' size={50} />
        </div>
      )}

      <Heading size='sm' className='border-b border-slate-200 pb-3'>
        Create Your AI Bot
      </Heading>

      <form className='mt-3 flex flex-col gap-4'>
        {fields.map(field => (
          <div key={field.name}>
            <div className='flex items-center gap-2'></div>
            <label
              htmlFor={field.name}
              className='text-sm font-medium text-gray-700'
            >
              {field.label}
            </label>
            {field.required ? (
              <Badge text='Required' color='indigo' />
            ) : (
              <Badge text='Optional' color='yellow' />
            )}
            {renderField(field)}
          </div>
        ))}
      </form>
      <div className='mt-3 flex gap-4'>
        <Button
          onClick={closeModal}
          variant='outline'
          size='md'
          className='w-full'
          text='Cancel'
        />
        <Button
          type='submit'
          disabled={isSubmitting || !isValid}
          onClick={() => handleSubmit()}
          size='md'
          className='w-full'
          text='Create'
        />
      </div>

      <button
        type='button'
        className='absolute right-5 top-5 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        onClick={closeModal}
      >
        <span className='sr-only'>Close</span>
        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
      </button>
    </div>
  );
};

export default CreateAssistant;
