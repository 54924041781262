import React, { ReactNode } from 'react';
import { cn } from '@utils/helpers';

type Props = {
  children: ReactNode;
  offset?: boolean;
  classNames?: string;
};

const ScrollableContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, offset, classNames }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('overflow-y-scroll', offset && '-mr-4 pr-2', classNames)}
      >
        {children}
      </div>
    );
  },
);

ScrollableContainer.displayName = 'ScrollableContainer';

export default ScrollableContainer;
