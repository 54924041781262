import classNames from 'classnames';
import React, { FC } from 'react';

type Props = {
  text: string;
  color?:
    | 'gray'
    | 'yellow'
    | 'green'
    | 'blue'
    | 'purple'
    | 'indigo'
    | 'purple'
    | 'pink'
    | 'red';
};

const Badge: FC<Props> = ({ color = 'indigo', text }) => {
  const getColor = () => {
    switch (color) {
      case 'gray':
        return 'text-gray-800 bg-gray-100';
      case 'green':
        return 'text-green-800 bg-green-100';
      case 'pink':
        return 'text-pink-800 bg-pink-100';
      case 'yellow':
        return 'text-yellow-800 bg-yellow-100';
      case 'blue':
        return 'text-blue-800 bg-blue-100';
      case 'purple':
        return 'text-purple-800 bg-purple-100';
      case 'red':
        return 'text-red-800 bg-red-100';
      case 'indigo':
        return 'text-indigo-800 bg-indigo-100';
    }
  };
  return (
    <span
      className={classNames(
        'ml-2 inline-flex items-center rounded-md px-2 py-0.5 text-sm font-medium',
        color && getColor(),
      )}
    >
      {text}
    </span>
  );
};

export default Badge;
