import {
  ArrowLeftOnRectangleIcon,
  BookOpenIcon,
  CreditCardIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import React, { ReactNode } from 'react';

interface DropdownItem {
  label: string;
  icon: ReactNode;
  link: string;
  divider?: boolean;
}

const iconStyles = 'mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500';

export const dropdownItems: DropdownItem[] = [
  {
    label: 'BetterWriter.ai (Deprecated)',
    icon: <BookOpenIcon className={iconStyles} />,
    link: '/writing_tools',
  },
  // {
  //   label: 'How to use',
  //   icon: <BookOpenIcon className={iconStyles} />,
  //   link: 'https://betterwriter.ai/blog',
  // },
  // {
  //   label: 'Billing',
  //   icon: <CreditCardIcon className={iconStyles} />,
  //   link: '/billing',
  // },
  {
    label: 'Help & Support',
    icon: <InformationCircleIcon className={iconStyles} />,
    link: 'mailto:hello@inputai.com',
  },
  {
    label: 'Sign out',
    icon: <ArrowLeftOnRectangleIcon className={iconStyles} />,
    link: '/api/internal/logout',
    divider: true,
  },
];
