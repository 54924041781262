import React, { FC } from 'react';
import { Assistant } from '../types';
import { User } from '@/types/global';
import Heading from '@components/typography/Heading';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';

type Props = {
  assistant: Assistant;
  user: User | undefined;
};

const AssistantHeaderDesktop: FC<Props> = ({ assistant, user }) => {
  const iconUrl = assistant.icon_url ?? '/emoji_robot.png';
  let modelText;
  switch (assistant.model) {
    case 'gpt-3.5-turbo':
      modelText = 'Using ChatGPT';
      break;
    case 'gpt-3.5-turbo-0613':
      modelText = 'Using ChatGPT';
      break;
    default:
      modelText = 'Using GPT-4';
      break;
  }

  return (
    <div className='mx-2 mb-3 h-fit w-full rounded-md bg-white p-4 shadow-md'>
      <Heading size='base' margin='sm'>
        <div className='flex items-center'>
          {iconUrl && (
            <div className='rounded-circle icon text-primary mr-2 flex h-[30px] w-[30px] items-center justify-center bg-white'>
              <div
                className='bg-light rounded-circle text-primary h-7 w-7'
                style={{
                  backgroundImage: `url(${
                    assistant.icon_url ||
                    'https://res.cloudinary.com/drrk2vuyp/image/upload/c_scale,w_50/v1686118314/chatgpt_e7bogd.png'
                  })`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '150%',
                  flexShrink: 0,
                }}
              />
            </div>
          )}

          <span style={{ flexShrink: 0 }}>{assistant.name}</span>

          <span className='text-lg'>
            <a
              className='ml-2'
              href={`/assistants/${assistant.slug}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ArrowTopRightOnSquareIcon className='mr-1 inline-block h-4 w-4 align-middle' />
            </a>
          </span>
          <span className='ml-2 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10'>
            {modelText}
          </span>
        </div>
      </Heading>

      <p className='text-muted mb-0 mt-2 leading-none'>
        {assistant.description}
      </p>

      {user?.is_admin && (
        <a
          href={`/admin/assistant/${assistant.id}`}
          target='_blank'
          rel='noreferrer'
        >
          <span>{`/admin/assistant/${assistant.id}`}</span>
        </a>
      )}
    </div>
  );
};

export default AssistantHeaderDesktop;
