import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import ReactTextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { cn } from '@utils/helpers';

export const textareaVariants = cva(
  'mt-2 w-full shadow-sm leading-normal text-sm text-slate-700 z-10',
  {
    variants: {
      variant: {
        default:
          'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
        error: 'border-red-400',
      },
      radius: {
        default: 'rounded-md',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      radius: 'default',
    },
  },
);

interface TextareaProps
  extends TextareaAutosizeProps,
    VariantProps<typeof textareaVariants> {
  variant?: 'default' | 'error';
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ variant = 'default', radius, minRows = 5, className, ...props }, ref) => {
    return (
      <ReactTextareaAutosize
        ref={ref}
        minRows={minRows}
        className={cn(textareaVariants({ variant, radius, className }))}
        {...props}
      />
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
