import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@utils/helpers';

export const inputVariants = cva('mt-2 w-full shadow-sm text-sm', {
  variants: {
    variant: {
      default: 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
      error: 'border-red-400',
    },
    radius: {
      default: 'rounded-md',
      full: 'rounded-full',
    },
  },
  defaultVariants: {
    variant: 'default',
    radius: 'default',
  },
});

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  variant?: 'default' | 'error';
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant = 'default', radius, className, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type='text'
        className={cn(inputVariants({ variant, radius, className }))}
        autoComplete='off'
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
