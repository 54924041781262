export interface RequestOptions {
  headers?: { [header: string]: string };
  credentials?: 'omit' | 'same-origin' | 'include';
  mode?: 'same-origin' | 'no-cors' | 'cors' | 'navigate';
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: string;
}

/**
 * For use with window.fetch
 */
export function jsonHeader(options: RequestOptions = {}): RequestOptions {
  return Object.assign(options, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });
}

// Additional helper methods

export function getMetaContent(name: string): string | null {
  const header = document.querySelector(
    `meta[name="${name}"]`,
  ) as HTMLMetaElement;
  return header?.content;
}

export function getAuthenticityToken(): string | null {
  return getMetaContent('csrf-token');
}

export function authenticityHeader(
  options: RequestOptions = {},
): RequestOptions {
  return Object.assign(options, {
    'X-CSRF-Token': getAuthenticityToken() || '',
    'X-Requested-With': 'XMLHttpRequest',
  });
}

/**
 * Lets fetch include credentials in the request. This includes cookies and other possibly sensitive data.
 * Note: Never use for requests across (untrusted) domains.
 */
export function safeCredentials(options: RequestOptions = {}): RequestOptions {
  return Object.assign(options, {
    credentials: 'include',
    mode: 'same-origin',
    headers: Object.assign(
      options.headers || {},
      authenticityHeader(),
      jsonHeader(),
    ),
  });
}

export async function handleErrors(response: Response) {
  if (!response.ok) {
    throw response;
  }
  return await response.json();
}

export function timeout<T>(time: number, promise: Promise<T>): Promise<T> {
  return new Promise<T>(function (resolve, reject) {
    const timer = setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise
      .then(value => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch(reason => {
        clearTimeout(timer);
        reject(reason);
      });
  });
}
